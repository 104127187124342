import Vue from 'vue'
import ListingEvent from '@/areas/events/models/ListingEvent'
import ListingEventChange from '@/areas/events/models/ListingEventChange'

export default Vue.extend({
  props: {
    path: [String]
  },
  computed: {
    events(): ListingEvent[] {
      return this.$store.getters['Events/selected']
    },
    hasChanges(): boolean {
      return this.changes.length > 0
    },
    changes(): ListingEventChange[] {
      if (!this.events || !this.path) {
        return []
      }
      const changes: ListingEventChange[] = []
      for (const event of this.events) {
        for (const change of event.changes) {
          if (RegExp(`${this.path}`, 'i').test(change.subject)) {
            changes.push(change)
          }
        }
      }

      return changes
    },
    changeSummary(): string {
      const messages = this.changes.map(change => {
        const date = this.$formatter.datetime(change.modifiedDate)
        let text = `[${date}]\n${change.description}`
        if (change.action === 'Updated') {
          text += `\n${change.oldValueFormatted} -> ${change.newValueFormatted}`
        }
        return text
      })

      return messages.join('\n\n')
    },
    highlightColor(): string {
      return this.changes.length ? 'yellow' : undefined
    }
  }
})
