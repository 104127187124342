import ViewModel from '@/models/ViewModel'
import IListing from './IListing'
import ISupportPolicy from '@/areas/supportPolicies/models/ISupportPolicy'
import IListingRelease from './IListingRelease'
import IListingSupportDate from './IListingSupportDate'
import IListingEdition from './IListingEdition'
import IPublishedListing from './IPublishedListing'
import IListingComment from './IListingComment'
import ListingContactModel from './ListingContactModel'
import IListingReleaseDataSource from './IListingReleaseDataSource'
import ListingPublishStatusModel from './ListingPublishStatusModel'
import ListingLinkModel from './ListingLinkModel'
import ListingNoteModel from './ListingNoteModel'

export default class ListingModel extends ViewModel implements IListing {
  $_saveComment: string
  $_familyId: number
  $_groupId: number
  $_reviewed: boolean
  id: number
  uniqueId: string
  modifiedDate: string
  modifiedBy: string
  concurrency: string
  publishHash?: string
  parentId: number
  parentTypeId: number
  productId: string
  displayName: string
  sortName: string
  description: string
  comments: string
  supportPolicyId: number
  listingTypeId: number
  isLocked: boolean
  isRemoved: boolean
  reviewed: boolean
  supportPolicy: ISupportPolicy
  oem: boolean
  highImpact: boolean
  listingReleases: IListingRelease[] = []
  listingSupportDates: IListingSupportDate[] = []
  listingEditions: IListingEdition[] = []
  publishedListings: IPublishedListing[] = []
  listingNotes: ListingNoteModel[] = []
  listingLinks: ListingLinkModel[] = []
  listingComments: IListingComment[] = []
  listingContacts: ListingContactModel[] = []
  listingPublishStatuses: ListingPublishStatusModel[] = []

  constructor(listing: IListing) {
    super()
    if (listing) {
      Object.assign(this, listing)
      const dataSources = this.dataSources

      this.listingContacts = listing.listingContacts?.map(x => {
        const contact = new ListingContactModel(x)
        for (const role of contact.listingContactRoles) {
          role.$_sourceDataName = dataSources.find(ds => ds.sourceDataId === role.sourceDataId)?.displayName
        }
        return contact
      })

      this.listingPublishStatuses = listing.listingPublishStatuses.map(x => new ListingPublishStatusModel(x, listing.publishHash))
      this.listingLinks = listing.listingLinks.map(x => new ListingLinkModel(x))
      this.listingNotes = listing.listingNotes.map(x => new ListingNoteModel(x))
    }
  }

  /** All listing data sources */
  get dataSources() {
    return this.listingReleases.reduce((dataSources, release) => {
      dataSources.push(...release.listingReleaseDataSources)
      return dataSources
    }, [] as IListingReleaseDataSource[])
  }

  getDataSourcesByEditionId(listingEditionId: number) {
    const releases = this.listingReleases

    const releaseDataSources: IListingReleaseDataSource[] = []
    releases.forEach(release => {
      release.listingReleaseDataSources.forEach(releaseDs => {
        if (releaseDs.listingEditionDataSources.some(x => x.listingEditionId === listingEditionId)) {
          releaseDataSources.push(releaseDs)
        }
      })
    })

    return releaseDataSources
  }
}
