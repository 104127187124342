












import EventHighlightMixin from '@/areas/events/mixins/EventHighlightMixin'

export default EventHighlightMixin.extend({
  model: {
    prop: 'inputValue',
    event: 'change'
  }
})
