















import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import IListingGroup from '../models/IListingGroup'
const { mapGetters } = createNamespacedHelpers('ListingHierarchies')

export default Vue.extend({
  props: {
    value: [String, Number],
    disabled: Boolean,
    familyId: {
      type: [Number, undefined]
    }
  },
  computed: {
    ...mapGetters(['groups']),
    items() {
      return this.groups.filter((x) => x.parentId === this.familyId)
    },
    tooltip(): string {
      const groups = this.groups as IListingGroup[]
      const selectedGroup = groups.find((group) => group.id === this.value)
      return selectedGroup?.name
    }
  }
})
