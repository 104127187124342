





















import Vue from 'vue'
import NotFound from '@/views/NotFound.vue'
import Unauthorized from '@/views/Unauthorized.vue'
import Upgrading from '@/views/Upgrading.vue'

export default Vue.extend({
  props: {
    title: String,
    subtitle: String,
    error: [Object, String, Error],
    loading: Boolean,
    skeleton: String
  },
  components: {
    NotFound,
    Unauthorized,
    Upgrading
  },
  watch: {
    error(error) {
      const ignoreCodes = [404, 403, 503]
      if (!ignoreCodes.includes(this.statusCode)) {
        this.$store.dispatch('showErrorAlert', error)
      }
    }
  },
  computed: {
    statusCode(): number {
      return this.error?.response?.status
    },
    showToolbar(): boolean {
      if (!this.$slots.toolbar) {
        return false
      }
      if ([403, 404, 503].includes(this.statusCode)) {
        return false
      }
      return true
    }
  }
})
