

































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import IListingFamily from '../models/IListingFamily'
import IListingGroup from '../models/IListingGroup'

const { mapGetters } = createNamespacedHelpers('ListingHierarchies')

export default Vue.extend({
  data() {
    return {
      showListingMenu: false
    }
  },
  computed: {
    ...mapGetters(['families'])
  },
  methods: {
    openFamily(family: IListingFamily, families: IListingFamily[]) {
      this.closeFamilies(families)
      this.$set(family, '$_open', true)
    },
    openGroup(group: IListingGroup, groups: IListingGroup[]) {
      this.closeGroups(groups)
      this.$set(group, '$_open', true)
    },
    closeAll() {
      this.closeFamilies(this.families)
      this.showListingMenu = false
    },
    closeFamilies(families: IListingFamily[]) {
      families.forEach(this.closeFamily)
    },
    closeFamily(family: IListingFamily) {
      this.closeGroups(family.$_groups)
      this.$set(family, '$_open', false)
    },
    closeGroups(groups: IListingGroup[]) {
      groups.forEach(this.closeGroup)
    },
    closeGroup(group: IListingGroup) {
      this.$set(group, '$_open', false)
    }
  }
})
