

























import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('Reports')

export default Vue.extend({
  computed: {
    ...mapGetters(['reports'])
  }
})
