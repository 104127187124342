






















import EventHighlightMixin from '@/areas/events/mixins/EventHighlightMixin'
import { ValidationProvider } from 'vee-validate'

export default EventHighlightMixin.extend({
  props: {
    rules: String,
    label: String,
    disabled: Boolean,
    debounce: {
      type: Number,
      default: 0
    }
  },
  components: {
    ValidationProvider
  }
})
