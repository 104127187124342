import ODataStore, { ODataState, ODataRequest, ODataResult } from '@/store/ODataStore'
import store from '@/store'
import IODataQuery from '@/models/IODataQuery'
import { cloneDeep } from 'lodash-es'
import IReport from './models/IReport'
import IReportData from './models/IReportData'

export interface StoreState extends Partial<ODataState<IReport>> {
  report: IReport
}

store.registerModule('Reports', ODataStore.extend<StoreState>({
  state: {
    report: null
  },
  getters: {
    reports(state) {
      return state.result.value
    },
    reportsCount(state) {
      return state.result.count
    },
    report(state) {
      return state.report
    },
    reportData(state) {
      return state.report?.$_reportData
    }
  },
  mutations: {
    setReport(state, report: IReport) {
      state.report = report
    }
  },
  actions: {
    async getReports({ commit, dispatch }) {
      const request: ODataRequest = {
        url: 'Reports'
      }
      const result = await dispatch('get', request) as ODataResult<IReport>

      commit('setResult', result)
    },
    async getReportById({ state, commit, dispatch }, { id, params }: { id: number, params: IODataQuery }) {
      let report = state.result.value.find(x => x.id === +id)
      report = cloneDeep(report)
      const url = `Reports/${id}/Run`
      const request: ODataRequest = {
        url,
        params
      }
      report.$_reportData = await dispatch('get', request) as IReportData
      commit('setReport', report)
    },
    async exportToFile({ state, dispatch }, id: number) {
      const report = state.result.value.find(x => x.id === +id)
      const request: ODataRequest = {
        url: `Reports/${id}/Export`,
        responseType: 'blob'
      }
      const response = await dispatch('get', request)
      const blob = new Blob([response], {
        type: response.type
      })
      const downloadUrl = window.URL.createObjectURL(blob)

      const link = document.createElement('a')

      link.href = downloadUrl

      link.setAttribute('download', `${report.name}.xlsx`)

      document.body.appendChild(link)

      link.click()
      link.remove()
    }
  }
}))
