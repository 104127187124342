import store from '@/store'
import ListingEvent from './models/ListingEvent'

interface IEventStoreState {
  selected: ListingEvent[]
}

store.registerModule<IEventStoreState>('Events', {
  namespaced: true,
  state: {
    selected: []
  },
  getters: {
    selected(state) {
      return state.selected || []
    }
  },
  mutations: {
    setSelected(state, events: ListingEvent[] = []) {
      state.selected = events
    }
  }
})
