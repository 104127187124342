import store from '@/store'
import Axios from 'axios'
import ISupportPolicy from './models/ISupportPolicy'

export interface ISupportPolicyStoreState {
  loading: boolean,
  supportPolicies: ISupportPolicy[]
}

store.registerModule<ISupportPolicyStoreState>('SupportPolicies', {
  namespaced: true,
  getters: {
    supportPolicies(state) {
      return state.supportPolicies || []
    },
    getSupportPolicyById: state => (id: number) => {
      return state.supportPolicies.find(x => x.id === id)
    },
    assignableSupportPolicies(state) {
      return state.supportPolicies.filter(x => x.isAssignableToListing)
    }
  },
  mutations: {
    setLoading(state, loading: boolean) {
      state.loading = loading
    },
    setSupportPolicies(state, supportPolicies: ISupportPolicy[]) {
      state.supportPolicies = supportPolicies
    }
  },
  actions: {
    async getSupportPolicies({ commit }) {
      try {
        commit('setLoading', true)
        const options = {
          params: {
            $orderby: 'displayName',
            $expand: 'supportPolicyDateTypes'
          }
        }
        const response = await Axios.get('SupportPolicies', options)
        commit('setSupportPolicies', response.data.value)
      } finally {
        commit('setLoading', false)
      }
    }
  }
})
