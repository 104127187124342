import store from '@/store'
import Axios from 'axios'
import IAlertType from './models/IAlertType'

export interface IAlertTypesStoreState {
  loading: boolean,
  error: Error,
  alertTypes: IAlertType[]
}

store.registerModule<IAlertTypesStoreState>('AlertTypes', {
  namespaced: true,
  getters: {
    all(state) {
      return state.alertTypes || []
    },
    loading(state) {
      return state.loading
    }
  },
  mutations: {
    setLoading(state, loading: boolean) {
      state.loading = loading
    },
    setAlertTypes(state, alertTypes: IAlertType[]) {
      state.alertTypes = alertTypes
    }
  },
  actions: {
    async getAlertTypes({ commit }) {
      try {
        commit('setLoading', true)
        const response = await Axios.get('NoteTypes', { params: { $orderby: 'name' } })
        commit('setAlertTypes', response.data.value)
      } finally {
        commit('setLoading', false)
      }
    }
  }
})
