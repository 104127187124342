import { v4 as uuidv4 } from 'uuid'
import { cloneDeep } from 'lodash-es'

export default class ViewModel {
  $_uuid = uuidv4()

  cloneDeep() {
    return cloneDeep(this)
  }
}
