import ISupportPolicy from '@/areas/supportPolicies/models/ISupportPolicy'
import ODataMixin from '@/mixins/ODataMixin'
import { IODataRequest } from '@/models/IODataRequest'
import { cloneDeep, max, min, minBy } from 'lodash-es'
import moment, { Moment } from 'moment'
import IListing from '../models/IListing'
import IListingEdition from '../models/IListingEdition'
import IListingRelease from '../models/IListingRelease'
import IListingReleaseDataSource from '../models/IListingReleaseDataSource'
import IListingSupportDate from '../models/IListingSupportDate'
import IPublishedReference from '../models/IPublishedReference'
import ListingLinkModel from '../models/ListingLinkModel'
import ListingModel from '../models/ListingModel'
import ListingNoteModel from '../models/ListingNoteModel'
import ListingPublishStatusModel from '../models/ListingPublishStatusModel'
import { PublishStatusEnum } from '../models/PublishStatusEnum'

// clean the data by removing properties that start with $_
const replacer = (key: string, value: any) => {
  if (key.startsWith('$_')) {
    return undefined
  }
  return value
}

export default ODataMixin.extend({
  data() {
    return {
      originalListing: null as ListingModel,
      listing: null as ListingModel,
      listingParams: {
        $expand: '*,listingReleases($expand=release,listingReleaseDataSources($expand=listingEditionDataSources)),listingEditions($expand=edition,listingEditionDataSources),publishedListings($expand=publishedlistingreferences($expand=externalSystem($select=id,shortName))),listingContacts($expand=listingContactRoles)'
      }
    }
  },
  computed: {
    hasChanges(): boolean {
      const originalString = JSON.stringify(this.originalListing, replacer)
      const currentString = JSON.stringify(this.listing, replacer)

      return originalString !== currentString
    },
    hasPublishableChanges(): boolean {
      // the approved value should not count as a change
      const originalClone = this.$clone(this.originalListing)
      const currentClone = this.$clone(this.listing)
      originalClone.listingPublishStatuses = []
      currentClone.listingPublishStatuses = []

      const originalString = JSON.stringify(originalClone, replacer)
      const currentString = JSON.stringify(currentClone, replacer)
      return originalString !== currentString
    },
    policy(): ISupportPolicy {
      return this.$store.getters['SupportPolicies/getSupportPolicyById'](this.listing.supportPolicyId) as ISupportPolicy
    },
    startDate(): Moment {
      // get listing start date
      const supportDates = this.listing.listingSupportDates
      const startDatePolicyTypeId = this.policy.supportPolicyDateTypes.find(x => x.isSupportStartDate).id
      const supportStartDate = supportDates.find(x => x.supportPolicyDateTypeId === startDatePolicyTypeId)

      if (!supportStartDate) {
        return null
      }

      return moment(supportStartDate.overrideValue || supportStartDate.value, 'YYYY-MM-DD')
    },
    listingDisabled(): boolean {
      return this.listing.isRemoved
    },
    publishableReleases(): IListingRelease[] {
      return this.listing.listingReleases.filter(x => x.publish || x.isPrivate)
    },
    publishableSourceData(): IListingReleaseDataSource[] {
      return this.publishableReleases.reduce((dataSources, release) => {
        dataSources.push(...release.listingReleaseDataSources)
        return dataSources
      }, [] as IListingReleaseDataSource[])
    }
  },
  watch: {
    'listing.listingReleases': {
      handler(newVal, oldVal) {
        if (newVal && oldVal) {
          this.updateSupportDates()
          this.updatePublishDate()
        }
      },
      deep: true
    },
    'listing.supportPolicyId': {
      handler(newVal, oldVal) {
        if (newVal && oldVal) {
          this.updateSupportDates()
        }
      }
    },
    'listing.listingSupportDates': {
      handler(newVal, oldVal) {
        if (newVal && oldVal) {
          this.updatePublishDate()
        }
      },
      deep: true
    }
  },
  methods: {
    async getListing(id?: number) {
      id = id || this.listing.id
      this.listing = null
      // Making multiple calls as a temp fix to boost performance on getting windows 10 listing.
      const promises = await Promise.all([
        this.getSingle<IListing>(`Listings(${id})`, { $expand: 'listingNotes($orderby=sortOrder),listingLinks($orderby=sortOrder),listingComments,listingSupportDates,listingKeywords,listingContacts($expand=listingContactRoles;$orderby=name)' }),
        this.getSingle<IListing>(`Listings(${id})`, { $expand: 'listingEditions($expand=edition,listingEditionDataSources)', $select: 'listingEditions' }),
        this.getSingle<IListing>(`Listings(${id})`, { $expand: 'listingPublishStatuses($expand=environment;$orderby=environmentId),publishedListings($expand=publishedlistingreferences($expand=externalSystem($select=id,shortName)))', $select: 'publishedListings' }),
        this.getSingle<IListing>(`Listings(${id})`, { $expand: 'listingReleases($expand=release,listingReleaseDataSources($expand=listingEditionDataSources))', $select: 'listingReleases' })
      ])

      const listing = new ListingModel(Object.assign({}, ...promises))

      const getById = this.$store.getters['ListingHierarchies/getById']
      listing.$_groupId = getById(listing.parentId).parentId
      listing.$_familyId = getById(listing.$_groupId).parentId
      listing.$_reviewed = listing.reviewed // store original value so we know when to hide checkbox on status panel
      this.listing = listing
      this.updateSupportDates(true)
      this.originalListing = cloneDeep(listing)
      await this.$store.dispatch('Releases/getReleasesByProductId', listing.parentId)
    },
    async refreshListingPublishStatus() {
      const clone = this.$clone(this.listing)
      const query = {
        $expand: 'listingPublishStatuses($expand=environment;$orderby=environmentId),publishedListings($expand=publishedlistingreferences($expand=externalSystem($select=id,shortName)))',
        $select: 'concurrency, publishHash'
      }
      const result = await this.getSingle<IListing>(`Listings(${clone.id})`, query)
      this.listing = new ListingModel(Object.assign(clone, result))
    },
    async saveListing(comment?: string) {
      const listing = this.listing

      if (listing.id) {
        // update
        let url = `Listings(${listing.id})`
        if (comment) {
          url += `?comment=${comment}`
        }
        await this.put(url, listing)
      } else {
        // create
        const result = await this.post<any>('Listings', listing)
        listing.id = result.data.id
      }

      this.$store.dispatch('showSuccessAlert', `The listing '${listing.displayName}' was saved.`)

      await this.getListing(listing.id)
    },
    async saveListingPublishStatus(publishStatus: ListingPublishStatusModel) {
      const clone = this.$clone(publishStatus)
      delete clone.environment
      await this.put(`ListingPublishStatuses(${clone.id})`, clone)
    },
    async discardListing() {
      const message = 'Are you sure you want to discard your changes?'
      if (
        !this.hasChanges ||
        (await this.$confirm(message, { title: 'Discard Changes?' }))
      ) {
        await this.getListing()
      }
    },
    async publishListing(publishStatus: ListingPublishStatusModel) {
      const request: IODataRequest = {
        method: 'POST',
        data: {
          environmentId: publishStatus.environmentId
        },
        concurrency: this.listing.concurrency
      }

      // Publish Preview
      if (publishStatus.environmentId === 1) {
        request.url = `Listings(${this.listing.id})/Publish`

        // save changes
        if (this.hasChanges) {
          await this.saveListing()
        }

        // publish
        await this.request(request)

        // reload listing
        await this.getListing()
      }

      // Publish Live
      if (publishStatus.environmentId > 1) {
        // notify user if there are unsaved changes, because publishing to live copies what is in preview to live. it doesn't copy the saved listing to live
        const preview = this.listing.listingPublishStatuses.find(x => x.environmentId === 1)
        const previewUpdateNeeded = preview.$_status === PublishStatusEnum.UpdateNeeded
        if (this.hasPublishableChanges || previewUpdateNeeded) {
          const message = `There are changes in this listing that will not get published to '${publishStatus.environment.name}'. Do you want to publish what is currently in 'Preview' anyway?`
          const confirmOptions = { color: 'warning', icon: '$warning', title: 'Listing Mismatch', buttonTrueColor: 'warning darken-2', buttonTrueText: 'Publish', buttonFalseText: 'Cancel' }
          if (!(await this.$confirm(message, confirmOptions))) {
            return
          }
        }

        const previousEnvironmentId = publishStatus.environmentId - 1

        // save changes to preview
        const previousPublishStatus = this.listing.listingPublishStatuses.find(x => x.environmentId === previousEnvironmentId)
        await this.saveListingPublishStatus(previousPublishStatus)

        // publish listing
        // have to get id of the previous published listing in order to "promote" it
        const previousPublishedListing = this.listing.publishedListings.find(x => x.environmentId === previousEnvironmentId)
        request.url = `PublishedListings(${previousPublishedListing.id})/Promote`
        request.concurrency = previousPublishedListing.concurrency

        await this.request(request)
        await this.refreshListingPublishStatus()

        // save listing
        if (this.hasPublishableChanges) {
          await this.saveListing()
        } else {
          // reload listing
          await this.getListing()
        }
      }
    },
    async unpublishListing(publishedReference: IPublishedReference) {
      if (await this.$confirm(`Are you sure you want to unpublish the listing from ${publishedReference.environment}?`, { title: 'Unpublish?' })) {
        await this.delete(`PublishedListings(${publishedReference.publishedListingId})`)
        await this.getListing()
      }
    },
    async archiveListing() {
      const message = 'Are you sure you want to archive this listing?'
      if (
        await this.$confirm(message, {
          title: 'Archive Listing?',
          buttonTrueText: 'Archive',
          buttonFalseText: 'Cancel'
        })
      ) {
        await this.post(`Listings(${this.listing.id})/Archive()`)
        await this.getListing()
      }
    },
    async restoreListing() {
      const message = 'Are you sure you want to restore this listing?'
      if (
        await this.$confirm(message, {
          title: 'Restore Listing?',
          buttonTrueText: 'Restore',
          buttonFalseText: 'Cancel'
        })
      ) {
        await this.post(`Listings(${this.listing.id})/Restore()`)
        await this.getListing()
      }
    },
    async newListing(displayName: string, productId: number, supportPolicyId: number, familyId: number) {
      const result = await this.getSingle<IListing>(`Listings/New(displayName='${encodeURIComponent(displayName)}',parentId=${productId},supportPolicyId=${supportPolicyId})`, { $expand: this.listingParams.$expand })
      const listing = new ListingModel(result)
      listing.$_familyId = familyId
      this.listing = listing
    },
    async seedListing(sourceDataId: number, supportPolicyId: number) {
      const result = await this.getSingle<IListing>(`Listings/Seed(sourceDataId=${sourceDataId},supportPolicyId=${supportPolicyId})`, { $expand: this.listingParams.$expand })
      this.listing = new ListingModel(result)
    },
    deleteEdition(listingEdition: IListingEdition) {
      if (!listingEdition) {
        return
      }
      const index = this.listing.listingEditions.findIndex(
        x => x.id === listingEdition.id
      )
      this.listing.listingEditions.splice(index, 1)
    },
    deleteEditions(editions: IListingEdition[]) {
      if (!Array.isArray(editions)) {
        return
      }
      editions.forEach(this.deleteEdition)
    },
    saveLink(link: ListingLinkModel) {
      const links = this.listing.listingLinks
      const index = links.indexOf(link)
      if (index >= 0) {
        links.splice(index, 1, link)
      } else {
        links.push(link)
      }
    },
    async deleteLink(link: ListingLinkModel) {
      if (await this.$confirm(`Are you sure you want to delete the link '${link.label}'?`, { title: 'Delete Link?' })) {
        const index = this.listing.listingLinks.indexOf(link)
        this.listing.listingLinks.splice(index, 1)
      }
    },
    updatePublishDate() {
      let publishDate: Moment = null

      // calculate the publish date
      // 1. max announcment date that is in the future
      const futureAnnouncementDates = this.publishableSourceData
        .filter(x => x.dataSourceName === 'D365')
        .map(x => moment(x.supportEndDate3))
      const maxFutureAnnouncementDate = max(futureAnnouncementDates)

      if (maxFutureAnnouncementDate > moment()) {
        publishDate = maxFutureAnnouncementDate
      }

      // 2. listing date that is in the future
      if (!publishDate && this.startDate > moment()) {
        publishDate = this.startDate
      }

      // 3. release start date that is in the future
      if (!publishDate) {
        const releases = this.publishableReleases
        const releaseDates = releases.map(x => moment(x.supportStartDateOverride || x.supportStartDate, 'YYYY-MM-DD'))
        const maxReleaseDate = max(releaseDates)

        if (maxReleaseDate > moment()) {
          publishDate = maxReleaseDate
        }
      }

      // set the publish date
      const preview = this.listing.listingPublishStatuses.find(x => x.environmentId === 1)
      if (!preview) {
        return
      }
      if (!publishDate) {
        preview.publishOn = null
        return
      }

      if (preview.publishOn) {
        const originalDate = moment(preview.publishOn)
        publishDate.hour(originalDate.hour())
        publishDate.minute(originalDate.minute())
      }

      preview.publishOn = publishDate.toISOString()
    },
    updateSupportDates(labelOnly?: boolean) {
      const listing = this.listing
      const originalDates = listing.listingSupportDates || []
      const newDates: IListingSupportDate[] = []
      const policy = this.policy
      const types = policy.supportPolicyDateTypes

      // get flat array of datasources accross all releases

      // gather arrays of dates for releases and data sources
      const releases = this.publishableReleases
      const releaseStartDates = releases.map(rel => rel.supportStartDateOverride || rel.supportStartDate)
      const releaseEndDates = releases.map(rel => rel.supportEndDateOverride || rel.supportEndDate)

      const sourceData = this.publishableSourceData
      const supportEndDates1 = sourceData.map(sourceData => sourceData.supportEndDate1)
      // const supportEndDates2 = sourceData.map(sourceData => sourceData.supportEndDate2)
      const supportEndDates3 = sourceData.map(sourceData => sourceData.supportEndDate3)

      // add missing support dates
      for (const type of types) {
        if (!type.isListingDate) {
          continue
        }

        let supportDate = originalDates.find(
          x => x.supportPolicyDateTypeId === type.id || x.$_label === type.label
        )

        supportDate = cloneDeep(supportDate) || {}

        supportDate.$_label = type.label
        supportDate.supportPolicyDateTypeId = type.id

        // skip updating values if 'update label only' mode
        if (labelOnly === true) {
          newDates.push(supportDate)
          continue
        }

        if (type.isSupportStartDate) {
          supportDate.value = min(releaseStartDates)
        }

        if (type.isSupportEndDate1) {
          const isNotFixed = policy.displayName !== 'Fixed'
          supportDate.value = isNotFixed ? max(releaseEndDates) : max(supportEndDates1)
        }

        if (type.isSupportEndDate2) {
          supportDate.value = max(releaseEndDates)
        }

        if (type.isSupportEndDate3) {
          supportDate.value = max(supportEndDates3) || max(releaseEndDates)
        }

        newDates.push(supportDate)
      }

      this.listing.listingSupportDates = newDates
    },
    updateReleaseDates(releaseId: number) {
      const release = this.listing.listingReleases.find(x => x.id === releaseId)
      const sources = release.listingReleaseDataSources
      release.supportStartDate = minBy(sources, x => x.supportStartDate)?.supportStartDate

      const endDates = sources.reduce((dates, source) => {
        dates.push(source.supportEndDate1)
        dates.push(source.supportEndDate2)
        dates.push(source.supportEndDate3)
        return dates
      }, [] as string[])

      release.supportEndDate = max(endDates)
    },
    deleteDataSource(releaseDataSource: IListingReleaseDataSource) {
      if (!releaseDataSource) {
        return
      }
      // delete release data source
      this.listing.listingReleases.forEach(release => {
        const index = release.listingReleaseDataSources.findIndex(ds => ds.id === releaseDataSource.id)
        if (index >= 0) {
          release.listingReleaseDataSources.splice(index, 1)
        }
      })

      // deleting linked contact
      const contacts = this.listing.listingContacts
      for (const contact of contacts) {
        contact.listingContactRoles = contact.listingContactRoles.filter(x => x.sourceDataId !== releaseDataSource.sourceDataId)
      }
      this.listing.listingContacts = contacts.filter(x => !!x.listingContactRoles.length)
      this.updateReleaseDates(releaseDataSource.listingReleaseId)
    },
    deleteDataSources(releaseDataSources: IListingReleaseDataSource[]) {
      if (!Array.isArray(releaseDataSources)) {
        return
      }
      releaseDataSources.forEach(this.deleteDataSource)
    },
    deleteRelease(release: IListingRelease) {
      if (!release) {
        return
      }
      this.deleteDataSources(release.listingReleaseDataSources)
      const index = this.listing.listingReleases.indexOf(release)
      this.listing.listingReleases.splice(index, 1)
    },
    async copyAlertToListings(alert: ListingNoteModel, listings: ListingModel[]) {
      const promises = listings.map(listing => {
        return this.post('ListingNotes', {
          listingId: listing.id,
          text: alert.text,
          noteTypeId: alert.noteTypeId
        })
      })
      await Promise.all(promises)
    },
    async copyLinkToListings(link: ListingLinkModel, listings: ListingModel[]) {
      const promises = listings.map(listing => {
        return this.post('ListingLinks', {
          listingId: listing.id,
          label: link.label,
          url: link.url
        })
      })
      await Promise.all(promises)
    }
  }
})
