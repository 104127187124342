import ViewModel from '@/models/ViewModel'
import IListingLink from './IListingLink'

export default class ListingLinkModel extends ViewModel implements IListingLink {
  id: number
  uniqueId: string
  modifiedDate: string
  modifiedBy: string
  listingId: number
  label: string
  url: string = 'https://'
  sortOrder: number
  migrationPath: boolean

  constructor(link?: IListingLink) {
    super()
    Object.assign(this, link)
  }
}
