<template>
  <v-footer app absolute>
    <v-col class="text-center body-2">&copy;{{ year }} Copyright Microsoft | <a href="https://privacy.microsoft.com/en-us/privacystatement" target="blank">Privacy Statement</a></v-col>
  </v-footer>
</template>

<script>
export default {
  computed: {
    year() {
      return new Date().getFullYear()
    }
  }
}
</script>
