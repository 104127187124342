import Vue from 'vue'
import Formatter from '@/utils/Formatter'

// formatting filters
Vue.filter('format', (value: any, name: string, options: any) => {
  if (!name) {
    return value
  }
  return Formatter.format(value, name, options)
})

Vue.filter('currency', (value: any, precision: number = 0) => {
  return Formatter.format(value, 'currency', { precision })
})

Vue.filter('number', (value: any, options: any) => {
  return Formatter.format(value, 'number', options)
})

Vue.filter('percent', (value: any, precision: number = 0) => {
  return Formatter.format(value, 'percent', { precision })
})

Vue.filter('date', (value: any) => {
  return Formatter.format(value, 'date')
})

Vue.filter('datetime', (value: any) => {
  return Formatter.format(value, 'datetime')
})

Vue.filter('isodate', (value: any) => {
  return Formatter.format(value, 'isodate')
})

Vue.filter('phone', (value: any) => {
  return Formatter.format(value, 'phone')
})

Vue.filter('bool', (value: any) => {
  return Formatter.format(value, 'bool')
})

Vue.filter('split', (value: any) => {
  return Formatter.format(value, 'split')
})

Vue.filter('csv', (value: any) => {
  return Array.isArray(value) ? value.join(', ') : value
})

Vue.filter('bytes', (value: any, precision: number) => {
  return Formatter.format(value, 'bytes', { precision })
})

Vue.filter('truncate', (value: any, length: number, omission: string = '...') => {
  return Formatter.truncate(value, { length, omission })
})
