import ODataStore, { ODataState, ODataRequest } from '@/store/ODataStore'
import store from '@/store'
import Filter from '@/models/Filter'
import IListingFlat from '../models/IListingFlat'

export interface IListingsStoreState extends Partial<ODataState<IListingFlat>> {
  filters: Filter[]
}

store.registerModule('Listings', ODataStore.extend<IListingsStoreState>({
  state: {
    filters: [
      {
        name: 'All',
        count: 0,
        filter: 'isRemoved eq false'
      },
      {
        name: 'Archived',
        count: 0,
        filter: 'isRemoved eq true'
      },
      {
        name: 'Needs Source Data Review',
        count: 0,
        filter: 'reviewed eq false and isRemoved eq false'
      },
      {
        name: 'Action Items',
        count: 0,
        filter: 'actionItems/any() and isRemoved eq false'
      },
      {
        name: 'Upcoming Publish',
        count: 0,
        filter: 'upcomingPublish eq true and isRemoved eq false'
      },
      {
        name: 'Updated Since Preview',
        count: 0,
        filter: 'previewCurrent eq false and previewPublishedDate ne null and isRemoved eq false'
      },
      {
        name: 'Ready For Live',
        count: 0,
        filter: 'previewCurrent eq true and liveCurrent eq false and isRemoved eq false'
      },
      {
        name: 'Live',
        count: 0,
        filter: 'livePublishedDate ne null and isRemoved eq false and allInternal eq false'
      },
      {
        name: 'Internal Only',
        count: 0,
        filter: 'internalOnly eq true and isRemoved eq false'
      }
    ]
  },
  getters: {
    listings(state) {
      return state.result.value
    },
    listingsCount(state) {
      return state.result.count
    },
    listingFilters(state) {
      return state.filters
    },
    getListingFilterQuery: state => (name: string) => {
      return state.filters.find(x => x.name === name)?.filter
    }
  },
  mutations: {
    setFilters(state, filters: Filter[]) {
      state.filters = filters
    }
  },
  actions: {
    async getFilterCounts({ state, rootGetters, commit, dispatch }, parentId: number) {
      const namedFilters = [...state.filters]
      const promises = namedFilters.map(namedFilter => {
        const request: ODataRequest = {
          url: 'listings/flat',
          params: {
            $count: true,
            $top: 0
          }
        }
        const filters: string[] = []
        if (namedFilter.filter) {
          filters.push(namedFilter.filter)
        }
        if (parentId) {
          const ids = rootGetters['ListingHierarchies/getDescendantsByParentId'](parentId)
            .map(x => x.id)
            .join(',')

          if (ids) {
            filters.push(`parentId in (${ids})`)
          }
        }
        request.params.$filter = filters.join(' and ') || undefined
        return dispatch('get', request)
      })

      const results = await Promise.all(promises)
      for (let i = 0; i < results.length; i++) {
        namedFilters[i].count = results[i]['@odata.count']
      }
      commit('setFilters', namedFilters)
    },
    async getListings({ commit, dispatch }, params) {
      const request: ODataRequest = {
        url: 'listings/flat',
        params
      }
      const result = await dispatch('get', request)
      commit('setResult', result)
    },
    async archiveListing({ dispatch }, listing: IListingFlat) {
      const request: ODataRequest = {
        url: `Listings(${listing.id})/Archive()`
      }
      await dispatch('post', request)
    },
    async restoreListing({ dispatch }, listing: IListingFlat) {
      const request: ODataRequest = {
        url: `Listings(${listing.id})/Restore()`
      }
      await dispatch('post', request)
    }
  }
}))
