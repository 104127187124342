import ViewModel from '@/models/ViewModel'
import { IListingContactRole } from './IListingContactRole'

export default class ListingContactRoleModel extends ViewModel implements IListingContactRole {
  $_sourceDataName: string
  id: number
  uniqueId: string
  modifiedDate: string
  modifiedBy: string
  listingContactId: number
  name: string
  sourceDataId: number

  constructor(listingContactRole?: IListingContactRole) {
    super()
    if (listingContactRole) {
      Object.assign(this, listingContactRole)
    }
  }
}
