













import ListingMixin from '../mixins/ListingMixin'
import CreateListingForm from '../components/CreateListingForm.vue'

export default ListingMixin.extend({
  components: {
    CreateListingForm
  },
  props: {
    value: Object
  },
  data() {
    return {
      form: null
    }
  },
  watch: {
    error(error) {
      this.$store.dispatch('showErrorAlert', error)
    },
    'value.supportPolicyId': 'init'
  },
  methods: {
    async init() {
      const sourceDataId = this.value?.dataSourceId
      const supportPolicyId = this.value?.supportPolicyId
      if (sourceDataId && supportPolicyId !== 6 && supportPolicyId !== 8) {
        await this.seedListing(sourceDataId, supportPolicyId)
      }
    },
    async onSubmit() {
      const form = this.form
      if (!form.dataSourceId) {
        await this.newListing(
          form.displayName,
          form.productId,
          form.supportPolicyId,
          form.familyId
        )
      }

      this.listing.displayName = form.displayName
      this.listing.parentId = form.productId
      this.listing.supportPolicyId = form.supportPolicyId
      this.listing.listingEditions = []
      this.listing.reviewed = true

      if (form.editionId) {
        this.listing.listingEditions.push({
          listingId: this.listing.id,
          editionId: form.editionId
        })
      }

      await this.saveListing()
      this.$emit('created', this.listing)
    }
  },
  created() {
    this.init()
  }
})
