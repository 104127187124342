import ViewModel from '@/models/ViewModel'
import IListingNote from './IListingNote'

export default class ListingNoteModel extends ViewModel implements IListingNote {
  id: number
  uniqueId: string
  modifiedDate: string
  modifiedBy: string
  listingId: number
  text: string
  noteTypeId: number
  sortOrder: number

  constructor(note?: IListingNote) {
    super()
    if (note) {
      Object.assign(this, note)
    }
  }
}
